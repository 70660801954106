import {
  type SanityGrid,
  type SanityGridColumnSize,
} from '@data/sanity/queries/types/modules'

type SpacingValues = Required<Required<SanityGrid>['spacing']>['padding']

type SpacingValueKey = keyof Required<SpacingValues>

type Direction = 'top' | 'right' | 'bottom' | 'left'

type ScreenSize = ' ' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'

const getSpacingValueKey = (direction: Direction, screenSize: ScreenSize) => {
  if (screenSize === ' ') {
    return direction as SpacingValueKey
  }

  const capitalizedDirection = `${direction
    .charAt(0)
    .toUpperCase()}${direction.slice(1)}`

  return `${screenSize}${capitalizedDirection}` as SpacingValueKey
}

export const getSpacingValuesFromGridColumnSizes = (
  sizes: SanityGridColumnSize[]
) => {
  let spacingValues: SpacingValues = {}

  // Add spacing from all column sizes into a single variable
  sizes.forEach((size) => {
    const screenSize = size.breakpoint as ScreenSize
    const values = size.spacing

    spacingValues = {
      ...spacingValues,
      [getSpacingValueKey('top', screenSize)]: values?.top,
      [getSpacingValueKey('right', screenSize)]: values?.right,
      [getSpacingValueKey('bottom', screenSize)]: values?.bottom,
      [getSpacingValueKey('left', screenSize)]: values?.left,
    }
  })

  return spacingValues
}

const getSpacingDirectionClasses = (
  typeClassPrefix: string,
  direction: Direction,
  spacingValues?: SpacingValues
): Record<string, boolean> => {
  const directionClassSuffix = direction.charAt(0)
  const spacingBaseClass = `${typeClassPrefix}${directionClassSuffix}`

  const defaultValue = spacingValues?.[getSpacingValueKey(direction, ' ')]
  const extraSmallValue = spacingValues?.[getSpacingValueKey(direction, 'xs')]
  const smallValue = spacingValues?.[getSpacingValueKey(direction, 'sm')]
  const mediumValue = spacingValues?.[getSpacingValueKey(direction, 'md')]
  const largeValue = spacingValues?.[getSpacingValueKey(direction, 'lg')]
  const extraLargeValue = spacingValues?.[getSpacingValueKey(direction, 'xl')]

  return {
    [`${spacingBaseClass}-${defaultValue}`]:
      typeof defaultValue !== 'undefined',
    [`xs:${spacingBaseClass}-${extraSmallValue}`]:
      typeof extraSmallValue !== 'undefined',
    [`sm:${spacingBaseClass}-${smallValue}`]: typeof smallValue !== 'undefined',
    [`md:${spacingBaseClass}-${mediumValue}`]:
      typeof mediumValue !== 'undefined',
    [`lg:${spacingBaseClass}-${largeValue}`]: typeof largeValue !== 'undefined',
    [`lx:${spacingBaseClass}-${extraLargeValue}`]:
      typeof extraLargeValue !== 'undefined',
  }
}

export const getSpacingClasses = (
  spacingValues?: SpacingValues,
  direction?: Direction
): Record<string, boolean> => {
  const topClasses = getSpacingDirectionClasses('p', 'top', spacingValues)
  const rightClasses = getSpacingDirectionClasses('p', 'right', spacingValues)
  const bottomClasses = getSpacingDirectionClasses('p', 'bottom', spacingValues)
  const leftClasses = getSpacingDirectionClasses('p', 'left', spacingValues)

  if (direction === 'top') {
    return topClasses
  }

  if (direction === 'right') {
    return rightClasses
  }

  if (direction === 'bottom') {
    return bottomClasses
  }

  if (direction === 'left') {
    return leftClasses
  }

  return {
    ...topClasses,
    ...rightClasses,
    ...bottomClasses,
    ...leftClasses,
  }
}

export const getDefaultGridVerticalPaddingClasses = (
  spacingValues?: SpacingValues
) => [
  // Top
  `pt-${spacingValues?.top ?? 12}`,
  `xs:pt-${spacingValues?.xsTop ?? 12}`,
  `sm:pt-${spacingValues?.smTop ?? 16}`,
  `md:pt-${spacingValues?.mdTop ?? 24}`,
  `lg:pt-${spacingValues?.lgTop ?? 32}`,
  `xl:pt-${spacingValues?.xlTop ?? 32}`,

  // Bottom
  `pb-${spacingValues?.bottom ?? 12}`,
  `xs:pb-${spacingValues?.xsBottom ?? 12}`,
  `sm:pb-${spacingValues?.smBottom ?? 16}`,
  `md:pb-${spacingValues?.mdBottom ?? 24}`,
  `lg:pb-${spacingValues?.lgBottom ?? 32}`,
  `xl:pb-${spacingValues?.xlBottom ?? 32}`,
]

export const getDefaultBlogVerticalPaddingClasses = (
  spacingValues?: SpacingValues
) => [
  // Top
  `pt-${spacingValues?.top ?? 12}`,
  `xs:pt-${spacingValues?.xsTop ?? 12}`,
  `sm:pt-${spacingValues?.smTop ?? 16}`,
  `md:pt-${spacingValues?.mdTop ?? 24}`,
  `lg:pt-${spacingValues?.lgTop ?? 32}`,
  `xl:pt-${spacingValues?.xlTop ?? 32}`,

  // Bottom
  `pb-${spacingValues?.bottom ?? 12}`,
  `xs:pb-${spacingValues?.xsBottom ?? 12}`,
  `sm:pb-${spacingValues?.smBottom ?? 16}`,
  `md:pb-${spacingValues?.mdBottom ?? 24}`,
  `lg:pb-${spacingValues?.lgBottom ?? 32}`,
  `xl:pb-${spacingValues?.xlBottom ?? 32}`,
]

export const getDefaultLogoGridVerticalPaddingClasses = (
  spacingValues?: SpacingValues
) => [
  // Top
  `pt-${spacingValues?.top ?? 8}`,
  `xs:pt-${spacingValues?.xsTop ?? 8}`,
  `sm:pt-${spacingValues?.smTop ?? 8}`,
  `md:pt-${spacingValues?.mdTop ?? 8}`,
  `lg:pt-${spacingValues?.lgTop ?? 8}`,
  `xl:pt-${spacingValues?.xlTop ?? 8}`,

  // Bottom
  `pb-${spacingValues?.bottom ?? 8}`,
  `xs:pb-${spacingValues?.xsBottom ?? 8}`,
  `sm:pb-${spacingValues?.smBottom ?? 8}`,
  `md:pb-${spacingValues?.mdBottom ?? 8}`,
  `lg:pb-${spacingValues?.lgBottom ?? 8}`,
  `xl:pb-${spacingValues?.xlBottom ?? 8}`,
]
