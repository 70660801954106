import cx from 'classnames'
import dynamic from 'next/dynamic'

import {
  type SanityAccordionsBlock,
  type SanityBlock,
  type SanityBlogPostCardBlock,
  type SanityContentCarouselBlock,
  type SanityFreeformBlock,
  type SanityNewsletterBlock,
  type SanityVideoBlock,
  type SanityCalendlyWidgetBlock,
  type SanityCookieDeclarationBlock,
  type SanityDemoFormBlock,
  type SanityHtmlBlock,
  type SanityIframeBlock,
  type SanityTeamMemberCardBlock,
  type SanityHorizontalTeamMemberCardBlock,
  type SanityPredefinedContentBlockBlock,
} from '@data/sanity/queries/types/blocks'

const Accordions = dynamic(() => import('@blocks/accordions'))
const BlogPostCard = dynamic(() => import('@blocks/blog/blog-post-card'))
const Freeform = dynamic(() => import('@blocks/freeform'))
const Newsletter = dynamic(() => import('@blocks/newsletter'))
const ContentCarousel = dynamic(() => import('@blocks/content-carousel'))
const CalendlyWidget = dynamic(() => import('@blocks/calendly-widget'))
const VideoModule = dynamic(() => import('./video'))
const CookieDeclaration = dynamic(
  () => import('@blocks/cookiebot/cookie-declaration')
)
const DemoForm = dynamic(() => import('@blocks/demo-form'))
const TeamMemberCard = dynamic(() => import('@blocks/team-member-card'))
const HorizontalTeamMemberCard = dynamic(
  () => import('@blocks/horizontal-team-member-card')
)

interface GridBlockProps {
  block: SanityBlock
}

const GridBlock = ({ block }: GridBlockProps) => {
  switch (block._type) {
    case 'accordions': {
      const { items } = block as SanityAccordionsBlock

      return <Accordions items={items} />
    }

    case 'blogPostCard': {
      const { post, options } = block as SanityBlogPostCardBlock

      return <BlogPostCard post={post} options={options} />
    }

    case 'calendlyWidget': {
      const { url, hideEventDetails, hideCookieSettings } =
        block as SanityCalendlyWidgetBlock

      return (
        <CalendlyWidget
          url={url}
          hideEventDetails={hideEventDetails}
          hideCookieSettings={hideCookieSettings}
        />
      )
    }

    case 'contentCarousel': {
      const { items } = block as SanityContentCarouselBlock

      return <ContentCarousel items={items} />
    }

    case 'cookieDeclaration': {
      const { active, cookieBotId } = block as SanityCookieDeclarationBlock

      if (!active || !cookieBotId) {
        return null
      }

      return <CookieDeclaration cookieBotId={cookieBotId} />
    }

    case 'demoForm': {
      const {
        _key,
        service,
        hubSpotFormId,
        hubSpotNewsletterFormId,
        klaviyoListID,
        klaviyoNewsletterListID,
        submit,
        successMsg,
        errorMsg,
        terms,
        newsletterStatement,
        buttonStyle,
        analytics,
        strings,
      } = block as SanityDemoFormBlock

      return (
        <DemoForm
          id={_key}
          service={service}
          hubSpotFormId={hubSpotFormId}
          hubSpotNewsletterFormId={hubSpotNewsletterFormId}
          klaviyoListID={klaviyoListID}
          klaviyoNewsletterListID={klaviyoNewsletterListID}
          submit={submit}
          successMsg={successMsg}
          errorMsg={errorMsg}
          terms={terms}
          newsletterStatement={newsletterStatement}
          buttonStyle={buttonStyle}
          analytics={analytics}
          strings={strings}
        />
      )
    }

    case 'freeform': {
      const { content, textAlign, maxWidth } = block as SanityFreeformBlock

      return (
        <Freeform content={content} textAlign={textAlign} maxWidth={maxWidth} />
      )
    }

    case 'html': {
      const { code } = block as SanityHtmlBlock

      return code ? <div dangerouslySetInnerHTML={{ __html: code }} /> : null
    }

    case 'iframe': {
      const { title, url, width, height } = block as SanityIframeBlock

      return (
        <iframe
          title={title}
          src={url}
          className={cx({ 'w-full': !width })}
          width={width}
          height={height}
        />
      )
    }

    case 'newsletter': {
      const {
        _key,
        title,
        description,
        variant,
        service,
        hubSpotFormId,
        klaviyoListID,
        submit,
        successMsg,
        errorMsg,
        terms,
      } = block as SanityNewsletterBlock

      return (
        <Newsletter
          id={_key}
          title={title}
          description={description}
          variant={variant}
          service={service}
          hubSpotFormId={hubSpotFormId}
          klaviyoListID={klaviyoListID}
          submit={submit}
          successMsg={successMsg}
          errorMsg={errorMsg}
          terms={terms}
        />
      )
    }

    case 'teamMemberCard': {
      const { teamMember } = block as SanityTeamMemberCardBlock

      return <TeamMemberCard teamMember={teamMember} />
    }

    case 'horizontalTeamMemberCard': {
      const { teamMember } = block as SanityHorizontalTeamMemberCardBlock

      return <HorizontalTeamMemberCard teamMember={teamMember} />
    }

    case 'predefinedContentBlock': {
      const { contentBlocks } = block as SanityPredefinedContentBlockBlock

      return (
        <>
          {contentBlocks?.map((contentBlock) => (
            <GridBlock key={contentBlock._key} block={contentBlock} />
          ))}
        </>
      )
    }

    case 'video': {
      const {
        type,
        vimeoVideo,
        muxVideo,
        settings,
        aspectRatio,
        subtitles,
        borderRadius,
      } = block as SanityVideoBlock

      return (
        <VideoModule
          type={type}
          vimeoVideo={vimeoVideo}
          muxVideo={muxVideo}
          settings={settings}
          aspectRatio={aspectRatio}
          subtitles={subtitles}
          borderRadius={borderRadius}
        />
      )
    }
  }
}

export default GridBlock
